import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  GET_AREAS,
  GET_CITIES,
  GET_COUNTRIES,
  GET_CURRENCIES,
  GET_STATES,
  SET_ORGANIZATION_ID,
  SET_SELECTED_CITY_ID,
  SET_SELECTED_COUNTRY_ID,
  SET_SELECTED_STATE_ID,
  SET_STATES,
  SET_CITIES,
  SET_AREAS,
  SET_STATES_LENGTH,
  SET_CITIES_LENGTH,
  SET_AREAS_LENGTH,
  SET_CITIES_LABEL,
  SET_AREAS_LABEL
} from "@/core/services/store/shipping_countries.module";
import swalCountry from "@/core/services/AlertServices/SwalCountry";

export default {
  data: () => ({
    countryRef: null,
    organization_id: null,
  }),
  mounted() {
    this.$store.dispatch(GET_CURRENCIES);
    this.$store.dispatch(GET_COUNTRIES).finally(() => {
      this.$store.commit(SET_PAGE_LOADING, false);
    });
  },
  methods: {
    handleAddFunction(itemType) {
      if (itemType === "country") this.$refs.addCountryDialog.toggleModal();
      else if (itemType === "state") {
        // if (this.$store.getters.getSHIPPINGCOUNTRIESStatesData.length === 0) {
        //   swalCountry("Please, select country");
        // } else
        this.$refs.addStateDialog.toggleModal();
      } else if (itemType === "city") {
        // if (this.$store.getters.getSHIPPINGCOUNTRIESCitiesData.length === 0) {
        //   swalCountry("Please, select state");
        // } else
        this.$refs.addCityDialog.toggleModal();
      } else {
        let label = this.$store.getters.getSHIPPINGCOUNTRIESAreasLabel;
        // we need to get area label to check it also, because if its value is `area is not found`,
        // our areas array in vuex is still be empty and when we check its length
        // without checking label it will be true, we will get warning alert,
        // but if area label is `Area not found` we have to get access adding area
        if (
          this.$store.getters.getSHIPPINGCOUNTRIESAreasData.length === 0 &&
          label !== "Area not found"
        ) {
          swalCountry("Please, select city");
        } else this.$refs.addAreaDialog.toggleModal();
      }
    },
    getStates(id, event) {
      let elem;
      if (event.target.firstElementChild) elem = event.target;
      else elem = event.target.parentElement.parentElement;
      this.$store.commit(SET_SELECTED_COUNTRY_ID, id);
      this.$store.commit(SET_PAGE_LOADING, true);
      if (this.countryRef) {
        this.countryRef.classList.remove("bg-light-info");
        this.countryRef.firstElementChild.firstElementChild.style.color =
          "rgba(0, 0, 0, 0.54)";
      }
      let payload = { country_id: id, type: "state" };
      if(this.$store.getters.getSHIPPINGCOUNTRIESOrganizationId){
        payload['organization_id'] = this.$store.getters.getSHIPPINGCOUNTRIESOrganizationId;
      }
      this.$store.dispatch(GET_STATES, payload).finally(() => {
        elem.classList.add("bg-light-info");
        elem.firstElementChild.firstElementChild.style.color = "#8950fc";
        this.countryRef = elem;
        this.$store.commit(SET_PAGE_LOADING, false);
      });
    },
    getCities(id, event) {
      let elem;
      if (event.target.firstElementChild) elem = event.target;
      else elem = event.target.parentElement.parentElement;
      this.$store.commit(SET_SELECTED_STATE_ID, id);
      this.$store.commit(SET_PAGE_LOADING, true);
      let payload = { state_id: id, type: "city" };
      if(this.$store.getters.getSHIPPINGCOUNTRIESOrganizationId){
        payload['organization_id'] = this.$store.getters.getSHIPPINGCOUNTRIESOrganizationId;
      }
      this.$store.dispatch(GET_CITIES, payload).finally(() => {
        elem.classList.add("bg-light-info");
        elem.firstElementChild.firstElementChild.style.color = "#8950fc";
        this.$store.commit(SET_PAGE_LOADING, false);
      });
    },
    getAreas(id, event) {
      let elem;
      if (event.target.firstElementChild) elem = event.target;
      else elem = event.target.parentElement.parentElement;
      this.$store.commit(SET_SELECTED_CITY_ID, id);
      this.$store.commit(SET_PAGE_LOADING, true);
      let payload = { city_id: id, type: "area" };
      if(this.$store.getters.getSHIPPINGCOUNTRIESOrganizationId){
        payload['organization_id'] = this.$store.getters.getSHIPPINGCOUNTRIESOrganizationId;
      }
      this.$store.dispatch(GET_AREAS, payload).finally(() => {
        elem.classList.add("bg-light-info");
        elem.firstElementChild.firstElementChild.style.color = "#8950fc";
        this.$store.commit(SET_PAGE_LOADING, false);
      });
    },
  },
  computed: {
    tableProperties: function () {
      return {
        title: "Countries",
        description: "Manage Countries",
      };
    },
  },
  watch: {
    organization_id: {
      handler(id) {
        this.$store.commit(SET_ORGANIZATION_ID, id);
        // this.$store.commit(SET_PAGE_LOADING, true);

        this.$store.commit(SET_STATES_LENGTH);
        this.$store.commit(SET_CITIES_LENGTH);
        this.$store.commit(SET_AREAS_LENGTH);

        this.$store.commit(SET_CITIES_LABEL, "Select a state");
        this.$store.commit(SET_AREAS_LABEL, "Select a city");
        this.$store.commit(SET_STATES, []);
        this.$store.commit(SET_CITIES, []);
        this.$store.commit(SET_AREAS, []);
        if (this.countryRef) {
          this.countryRef.classList.remove("bg-light-info");
          this.countryRef.firstElementChild.firstElementChild.style.color =
            "rgba(0, 0, 0, 0.54)";
        }
        // this.$store.commit(SET_PAGE_LOADING, false);
      },
      deep: true,
    },
  },
};
